// App.js
import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import BirthdateForm from './components/BirthdateForm/BirthdateForm';
import PDFViewer from './components/PDFViewer/PDFViewer';
import ShortUrlRedirect from './components/ShortUrlRedirect/ShortUrlRedirect';
import NotFoundPage from './components/Page/NotFoundPage';
import History from "./components/History/History";
import { ChakraProvider } from "@chakra-ui/react"

function App() {
    return (
        <ChakraProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/:shorturl" element={<ShortUrlRedirect />} />
                    <Route path="/birthdate/:shorturl" element={<BirthdateForm />} />
                    <Route path="/pdf-viewer" element={<PDFViewer />} />
                    <Route path="/history" element={<History />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
         </ChakraProvider>
    );
}

export default App;

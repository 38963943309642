import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {useLocation, useNavigate} from 'react-router-dom';
import './PDFViewer.css';
import axios from "../../plugins/axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer() {
    const navigate = useNavigate();
    const location = useLocation();
    const { pdfFile } = location.state || {};
    const [numPages, setNumPages] = useState(null);
    // 뷰포트 너비에 따른 조건부 초기화
    const isMobile = window.innerWidth < 768; // 768px 미만을 모바일로 가정
    const initialScale = isMobile ? 0.72 : 1.35; // 모바일에서는 기본 scale을 0.8로, PC에서는 1로 설정
    const [scale, setScale] = useState(initialScale);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const pageRefs = useRef([]);
    const [pdfFileName, setPdfFileName] = useState('');

    useEffect(() => {
        if (!location.state?.pdfFile) {
            navigate('/');
        }else {
            setPdfFileName(location.state.pdfFile.split('/').pop());
        }

        if (!location.state?.shortUrl) {
            navigate('/');
        }else {
            // 백엔드로 shorturl 전달하는 API 호출
            axios.get(`/save/openpdf/${location.state.shortUrl}`)
                .then(response => {
                })
                .catch(error => {
                    navigate("/")
                });
        }

        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 2;
            const page = pageRefs.current.findIndex((ref, index) =>
                ref.current && scrollPosition >= ref.current.offsetTop && scrollPosition < ref.current.offsetTop + ref.current.clientHeight
            );
            if (page !== -1 && page + 1 !== currentPageNumber) {
                setCurrentPageNumber(page + 1);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentPageNumber, pageRefs]);

    const handleZoomOut = () => {
        setScale(scale => scale > 0.5 ? scale * 0.9 : scale); // 최소 50%까지 축소
    };

    const handleZoomIn = () => {
        setScale(scale => scale < 2 ? scale * 1.1 : scale); // 최대 200%까지 확대
    };


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        pageRefs.current = new Array(numPages).fill(null).map((_, i) => pageRefs.current[i] || React.createRef());
    };

    return (
        <div className="pdf-viewer-container">
            <div className="pdf-overlay"></div> {/* 배경 오버레이 추가 */}
            <div className="controls">
                <div className="pdf-file-name">{pdfFileName}</div> {/* PDF 파일명 표시 */}
                <div className="zoom-controls">
                    <span> {currentPageNumber} / {numPages}</span>
                    <button id="zoom-out" onClick={handleZoomOut}>-</button>
                    <span id="zoom-level">{(scale * 100).toFixed(0)}%</span>
                    <button id="zoom-in" onClick={handleZoomIn}>+</button>
                </div>
            </div>
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <div key={index} ref={pageRefs.current[index]} className="page-container">
                        <Page pageNumber={index + 1} renderAnnotationLayer={false} renderTextLayer={false} scale={scale} />
                    </div>
                ))}
            </Document>
        </div>
    );
}

export default PDFViewer;

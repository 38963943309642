import React, { useState, useEffect } from 'react';
import './BirthdateForm.css';
import MobileLayout from "../layout/MobileLayout";
import DesktopLayout from "../layout/PCLayout";

function BirthdateForm() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {isMobile ? (
                // 모바일 환경일 때 렌더링할 컴포넌트
                <MobileLayout />
            ) : (
                // 데스크탑 환경일 때 렌더링할 컴포넌트
                <DesktopLayout />
            )}
        </div>
    );
}

export default BirthdateForm;

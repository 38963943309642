import React, {useEffect, useState} from 'react';
import styles from '../../styles/style.module.css';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "../../plugins/axios";
import SVGLogoComponent from "../Svgviewer/logo";


function DesktopLayout() {
    const [birthdate, setBirthdate] = useState('');
    const {shorturl } = useParams(); // URL 파라미터에서 shorturl 추출
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // userName이 location.state에 없는 경우 에러 페이지로 리디렉션
        if (!location.state?.userName) {
            navigate('/');
        }else {
            setUserName(location.state.userName);
        }
    }, [location.state, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // 8자리 숫자인지 확인
        if (!/^\d{8}$/.test(birthdate)) {
            setErrorMessage('생년월일은 8자리 숫자여야 합니다. 예: YYYYMMDD');
            return;
        }
        setErrorMessage('');

        try {
            const response = await axios.get(`/user/${shorturl}/${birthdate}`);
            // 인증 성공, 응답으로 받은 PDF 파일 경로를 PDFViewer 컴포넌트에 전달
            if (response.data.result === 'Y') {
                navigate('/pdf-viewer', { state: { pdfFile: '/file/' + response.data.filePath +'.pdf', shortUrl : response.data.shortURL } });
            } else {
                // 서버 측 인증 실패 처리
                setErrorMessage('생년월일이 유효하지 않습니다.');
            }
        } catch (error) {
            setErrorMessage('인증 요청 실패');
        }
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.header} id="header">
                <div className={styles.header_search}>
                    <div className={styles.header_search_cont}>
                        <div className={styles.h1_logo}><a href="https://www.mostx.co.kr">< SVGLogoComponent alt="상단 로고" title="상단 로고" /></a></div>
                    </div>
                </div>
            </div>
            <article className={styles.main}>
                <section className={styles.main_text}>
                    <h2>채권양도통지서 열람 서비스</h2>
                    <p>저희 회사는 통상적으로 고객님들의 렌탈채권을 담보로 금융사로부터 대출을 받아 회사의 운영자금으로 활용하고, 이를 통해 지속적으로 회사를 성장시켜 왔습니다.</p>
                    <p>저희 회사는 23.12.26일 자로 고객님의 렌탈채권을 담보로 금융사로부터 대출을 실행하게 되었고, 이에 따라 업무 행정 절차로 금융사인 수협은행에서 고객님께 렌탈채권 양도 통지서를 내용증명으로 발송되었습니다.</p>
                    <p>이는 대출 기간 동안 고객님의 렌탈채권을 당사에서 금융사에 양도하여 관리하도록 하기 위함으로 당사와 고객님과의 렌탈 이용에 관한 모든 내용과 계약 등은 아무런 변동과 상관이 없으며 고객님의 신용과 재산상의 문제에는 어떠한 영향이 없음을 다시 한번 알려드립니다.</p>
                    <p>양도통지서를 열람하시게 되면 법률용어 등을 포함해 이해하기 어려운 내용으로 되어 있지만, 위에서 말씀드린 것처럼 저희 회사에서 렌탈채권을 담보로 대출 유동화를 하면서 형식적으로 보내는 문서이니 참고만 하시면 되겠습니다.</p>
                    <p>앞으로도 더 좋은 제품과 서비스로 고객님을 찾아뵐 수 있도록 최선을 다하겠습니다.</p>
                    <p>감사합니다.</p>
                </section>
                <section>
                    <form onSubmit={handleSubmit}>
                        <ul className={styles.form}>
                            <li>
                                <p className={styles.title}>이름</p>
                                <p className={styles.text}>{userName}</p>
                            </li>
                            <li>
                                <label htmlFor="" className={styles.title}>생년월일</label>
                                <input type="password" id="birthdate"
                                       name="birthdate"
                                       value={birthdate}
                                       onChange={(e) => setBirthdate(e.target.value)}
                                       placeholder="숫자 8자리" maxLength="8" className={styles.text}
                                       autoComplete="off"/>
                            </li>
                            <li>
                                <p className={styles.info}>*생년월일 숫자 8자리를 입력해주세요</p>
                            </li>
                            <li>
                                {errorMessage && <div className="error-message">{errorMessage}</div>}
                            </li>
                            <li>
                                <button className={styles.btn} type="submit">조회하기</button>
                            </li>
                        </ul>
                        <p className={styles.info}> *'계약자' 이름이 본인이 아니실 경우 고객센터 02-511-2985로 연락해주시기 바랍니다.</p>
                        <p className={styles.info}> * 해당 서비스는 열람 안내 통지를 발송한 날로 부터 1개월간 열람이 가능합니다</p>
                    </form>
                </section>
            </article>
            <footer className={styles.footer}>
                <div id="footer_wrap">
                    <div id="footer">
                        <div className={styles.foot_cont}>
                            <div>
                                <ul className={styles.familysite}>
                                    <li><a target="_blank" href="https://www.modurentalmall.co.kr/">MODU</a></li>
                                    <li><a target="_blank" href="https://ecoce.co.kr/">ECOCE</a></li>
                                    <li><a target="_blank" href="https://www.lukins.co.kr/">LUKINS</a></li>
                                </ul>
                                <p className={styles.copyright}>© MOSTX. ALL RIGHT RESERVED.</p>
                            </div>
                            <div className={styles.foot_info}>
                                <div className={styles.foot_info_list}>
                                    <dl>
                                        <dt>대표이사</dt>
                                        <dd>김기범</dd>
                                    </dl>
                                    <dl>
                                        <dt>사업자등록번호</dt>
                                        <dd>107-86-37025</dd>
                                    </dl>
                                </div>
                                <div className={styles.foot_info_list}>
                                    <dl>
                                        <dt>전화</dt>
                                        <dd>02-2009-6000 (평 일 : 09:00 ~ 18:00 점심시간 : 12:00 ~ 13:00)</dd>
                                    </dl>
                                    <dl>
                                        <dt>팩스</dt>
                                        <dd>02-6008-0189</dd>
                                    </dl>
                                    <dl>
                                        <dt>주소</dt>
                                        <dd>
                                            <address>
                                                <span>(06098) 서울특별시 강남구 학동로 306 (곤비) 곤비빌딩 3층, 4층, 6층, 7층, 지하1~2층</span>
                                            </address>
                                        </dd>
                                    </dl>
                                </div>
                                <div className={styles.foot_info_list}>
                                    <dl>
                                        <dt>양도통지 내용증명 고객센터</dt>
                                        <dd>02-511-2985 (평일 : 09:00 ~ 18:00)</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default DesktopLayout;

import React, { useState } from 'react';
import axios from "../../plugins/axios";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/chakra-ui';
import { useSort } from '@table-library/react-table-library/sort';
import { usePagination } from '@table-library/react-table-library/pagination';
import {
    Box,
    Stack,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    Button,
    IconButton, HStack, InputRightElement, Collapse
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp, FaSearch,FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const History = () => {
    const [phoneNumber, setPhoneNumber] = useState(''); // 전화번호 검색 상태
    // nodes 상태를 useState로 관리합니다.
    const [nodes, setNodes] = useState([]);
    const chakraTheme = getTheme(DEFAULT_OPTIONS);
    const customTheme = {
      Table: `
        .animate {
        grid-column: 1 / -1;

        display: flex;
      }

      .animate > div {
        flex: 1;
        display: flex;
      }
       --data-table-library_grid-template-columns: 8ch auto;
      `,
    };
    const theme = useTheme([chakraTheme, customTheme]);

    let data = {
        nodes: nodes
    };

    const [ids, setIds] = React.useState([]);

    const handleExpand = (item) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const ROW_PROPS = {
        onClick: handleExpand,
    };

    const ROW_OPTIONS = {
        renderAfterRow: (item) => (
            <Collapse className="animate" in={ids.includes(item.id)}>
                <tr style={{ flex: '1', display: 'flex' }}>
                    <td style={{ flex: '1' }}>
                        <ul
                            style={{
                                backgroundColor: "#f5f5f5",
                                margin: '0',
                                padding: '0',
                            }}
                        >
                            <li>
                                <strong>전화번호:</strong> {item.userTel}
                            </li>
                            <li>
                                <strong>저장타입:</strong> {item.regType}
                            </li>
                            <li>
                                <strong>성공유무:</strong> {item.isSuccess}
                            </li>
                            <li>
                                <strong>실패사유:</strong> {item.errorMessage}
                            </li>
                            <li>
                                <strong>호출일자:</strong> {item.strRegDate}
                            </li>
                        </ul>
                    </td>
                </tr>
            </Collapse>
        ),
    };


    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    const handlePhoneNumberSearch = async () => {
        try {
            const response = await axios.get(`/user/history/${phoneNumber}`);
            setNodes(response.data.data || []);
        } catch (error) {
            setNodes([]); // 에러 발생 시 nodes를 비움
        }
    };
    // Enter 키 입력 감지 및 처리를 위한 핸들러
    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            await handlePhoneNumberSearch();
        }
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 10,
        },
        onChange: onPaginationChange,
    });

    function onPaginationChange(action, state) {
        console.log(action, state);
    }

    const getVisiblePages = (page, total) => {
        const visiblePages = 2; // 현재 페이지 앞뒤로 보이는 페이지 수
        let start = Math.max(page - visiblePages, 0); // 시작 페이지 번호는 음수가 될 수 없음
        let end = Math.min(start + visiblePages * 2, total - 1); // 마지막 페이지 번호는 총 페이지 수를 초과할 수 없음

        // 시작 페이지가 0인 경우 또는 총 페이지 수에 가까운 경우 조정
        if (start === 0) {
            end = Math.min(visiblePages * 2, total - 1);
        } else if (end === total - 1) {
            start = Math.max(total - 1 - visiblePages * 2, 0);
        }

        return Array.from({length: (end - start) + 1}, (_, i) => start + i);
    };
    // 현재 페이지 번호와 총 페이지 수를 기반으로 보여줄 페이지 번호 계산
    const visiblePages = getVisiblePages(pagination.state.page, pagination.state.getTotalPages(data.nodes));

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortIcon: {
                iconDefault: null,
                iconUp: <FaChevronUp />,
                iconDown: <FaChevronDown />,
            },
            sortFns: {
                TELEPHONE: (array) => array.sort((a, b) => {
                    if (a.userTel === null && b.userTel === null) return 0;
                    if (a.userTel === null) return 1;
                    if (b.userTel === null) return -1;
                    return a.userTel.localeCompare(b.userTel);
                }),
                REGISTRATION_TYPE: (array) => array.sort((a, b) => {
                    if (a.regType === null && b.regType === null) return 0;
                    if (a.regType === null) return 1;
                    if (b.regType === null) return -1;
                    return a.regType.localeCompare(b.regType);
                }),
                IS_SUCCESS: (array) => array.sort((a, b) => {
                    if (a.isSuccess === null && b.isSuccess === null) return 0;
                    if (a.isSuccess === null) return 1;
                    if (b.isSuccess === null) return -1;
                    return a.isSuccess.localeCompare(b.isSuccess);
                }),
                ERROR_MESSAGE: (array) => array.sort((a, b) => {
                    if (a.errorMessage === null && b.errorMessage === null) return 0;
                    if (a.errorMessage === null) return 1;
                    if (b.errorMessage === null) return -1;
                    return a.errorMessage.localeCompare(b.errorMessage);
                }),
                REGISTRATION_DATE: (array) => array.sort((a, b) => {
                    if (a.strRegDate === null && b.strRegDate === null) return 0;
                    if (a.strRegDate === null) return 1;
                    if (b.strRegDate === null) return -1;
                    return a.strRegDate.localeCompare(b.strRegDate);
                }),
            },
        },
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    const COLUMNS = [
        { label: '전화번호', renderCell: (item) => item.userTel, sort: { sortKey: 'TELEPHONE' } },
        { label: '저장타입', renderCell: (item) => item.regType, sort: { sortKey: 'REGISTRATION_TYPE' } },
        { label: '성공유무', renderCell: (item) => item.isSuccess, sort: { sortKey: 'IS_SUCCESS' } },
        { label: '실패사유', renderCell: (item) => item.errorMessage, sort: { sortKey: 'ERROR_MESSAGE' } },
        { label: '호출일자', renderCell: (item) => item.strRegDate, sort: { sortKey: 'REGISTRATION_DATE' } },
    ];

    const totalPages = pagination.state.getTotalPages(data.nodes);

    // "이전 페이지로" 버튼 클릭 핸들러
    const handlePreviousPage = () => {
        const newPage = Math.max(0, pagination.state.page - 1);
        pagination.fns.onSetPage(newPage);
    };

    // "다음 페이지로" 버튼 클릭 핸들러
    const handleNextPage = () => {
        const newPage = Math.min(totalPages - 1, pagination.state.page + 1);
        pagination.fns.onSetPage(newPage);
    };

    return (
        <Flex align={"center"} justify={"center"} height={'100vh'}>
            <Stack spacing={4} width="80%">
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<FaSearch style={{ color: '#4a5568' }} />}
                    />
                    <Input
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onKeyDown={handleKeyPress} // Enter 키 입력 감지 이벤트 핸들러 추가
                    />
                    <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onChange={handlePhoneNumberChange} onClick={handlePhoneNumberSearch}>
                            검색
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <Box p={3} borderWidth="1px" borderRadius="lg" overflowX="auto"  boxShadow="md" >
                    <CompactTable
                        columns={COLUMNS}
                        data={data}
                        sort={sort}
                        theme={theme}
                        pagination={pagination}
                        rowProps={ROW_PROPS}
                        rowOptions={ROW_OPTIONS}
                    />
                </Box>
                <HStack justify="flex-end">
                    <IconButton
                        aria-label="previous page"
                        icon={<FaChevronLeft />}
                        colorScheme="teal"
                        variant="ghost"
                        disabled={pagination.state.page <= 0}
                        onClick={handlePreviousPage}
                    />
                    {visiblePages.map(index => (
                        <Button
                            key={index}
                            colorScheme="teal"
                            variant={pagination.state.page === index ? 'solid' : 'ghost'}
                            onClick={() => pagination.fns.onSetPage(index)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <IconButton
                        aria-label="next page"
                        icon={<FaChevronRight />}
                        colorScheme="teal"
                        variant="ghost"
                        disabled={pagination.state.page >= totalPages - 1}
                        onClick={handleNextPage}
                    />
                </HStack>
            </Stack>
        </Flex>
    );
};

export default History;

import React from 'react';
import './NotFoundPage.css';

function NotFoundPage() {
    return (
        <div className="NotFound">
            <div className="NotFound-container">
                <h1>404</h1>
                <p>죄송합니다.</p>
                <p>잘못된 요청입니다.</p>
                <p>입력하신 주소가 정확한지 확인해 주시기 바랍니다.</p>
            </div>
        </div>
    );
}

export default NotFoundPage;

import * as React from "react";
const SVGLogoWComponent = (props) => (
  <svg
    id="logo_w"
    x="0px"
    y="0px"
    viewBox="0 0 190 62"
    style={{
      enableBackground: "new 0 0 190 62",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">{"\n\t.logo_w_st0{fill:#FFFFFF;}\n"}</style>
    <switch>
      <foreignObject
        requiredExtensions="&ns_ai;"
        x={0}
        y={0}
        width={1}
        height={1}
      >
      </foreignObject>
      <g>
        <g>
          <g>
            <g>
              <polygon
                className="logo_w_st0"
                points="160.9,35.5 168.8,44 172.9,44 163,33.4  "
              />
            </g>
            <g>
              <polygon
                className="logo_w_st0"
                points="185,5 180.9,5 160.9,26.5 163,28.6  "
              />
            </g>
            <g>
              <polygon
                className="logo_w_st0"
                points="148.6,18 144.6,18 156.7,31 132.5,57 136.5,57 160.8,31  "
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="logo_w_st0"
                d="M65.2,18H48.6C46,18,44,20.1,44,22.6v16.8c0,2.5,2.1,4.6,4.6,4.6h16.7c2.5,0,4.6-2.1,4.6-4.6V22.6 C69.8,20.1,67.8,18,65.2,18z M66.9,41H48.5c-0.8,0-1.5-0.7-1.5-1.5V21h18.4c0.8,0,1.5,0.7,1.5,1.5V41z"
              />
            </g>
            <g>
              <path
                className="logo_w_st0"
                d="M31.6,18H9.8C7.1,18,5,20.1,5,22.8V44h3V21h9.8c0.8,0,1.5,0.7,1.5,1.5V44h3V22.5c0-0.8,0.7-1.5,1.5-1.5 h9.6v23h3V22.8C36.3,20.1,34.2,18,31.6,18z"
              />
            </g>
            <g>
              <polygon
                className="logo_w_st0"
                points="110.9,21 122.4,21 122.4,44 125.3,44 125.3,21 136.8,21 136.8,18 110.9,18  "
              />
            </g>
            <g>
              <path
                className="logo_w_st0"
                d="M77.5,22.6v2.8c0,2,1.4,3.8,3.3,4.4l18.4,5.3c0.7,0.2,1.1,0.8,1.1,1.5V41H77.5v3h21.2 c2.5,0,4.6-2.1,4.6-4.6v-2.8c0-2-1.4-3.8-3.3-4.4l-18.4-5.3c-0.7-0.2-1.1-0.8-1.1-1.5V21h22.9v-3H82.1 C79.5,18,77.5,20.1,77.5,22.6z"
              />
            </g>
          </g>
        </g>
      </g>
    </switch>
    <i/>
  </svg>
);
export default SVGLogoWComponent;

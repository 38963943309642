import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "../../plugins/axios";

function ShortUrlRedirect() {
    const { shorturl } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // 백엔드로 shorturl 전달하는 API 호출
        axios.get(`/url/${shorturl}`)
            .then(response => {
                if(response.data.result === 'Y'){
                    navigate(`/birthdate/${shorturl}`,{state:{userName :response.data.userName}});
                }else{
                    navigate("/")
                }
            })
            .catch(error => {
                if (error.response) {
                    // 서버가 상태 코드와 함께 응답을 반환했지만, 요청이 실패한 상태 코드 (예: 404, 500)
                    console.log(error.response.status);
                    if (error.response.status === 404) {
                        console.log("404 오류! 페이지를 찾을 수 없습니다.");
                    } else if (error.response.status === 500) {
                        console.log("500 오류! 서버 내부 오류가 발생했습니다.");
                    }
                }
                navigate("/")
            });
    }, [shorturl, navigate]);

    return <div>Loading...</div>;
}

export default ShortUrlRedirect;
